import React from "react";
import img from "../../assets/image/media/design/png/design_hero.png";

const Hero = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container pt-25 pt-lg-31 pb-13 pb-lg-18">
        <div className="row align-items-center">
          {/* Hero Content */}
          <div className="col-xl-6 col-lg-6 col-md-6 col-xs-8 order-2 order-lg-1">
            <div
              className="mt-8 mt-lg-0"
              data-aos="fade-right"
              data-aos-delay={500}
            >
              <p className="font-size-4 mb-4">PIXEL PERFECT</p>
              <h2 className="font-size-10 mb-8">
                We're making design better for everyone
              </h2>
              <p className="font-size-6 mb-0">
                Product design isn’t just about aesthetics—it’s about creating
                an interface that your users will engage with, return to, and
                delight in. To achieve this we combine innovative visual
                creativity with science-backed UX/UI best practices to create
                responsive designs which look stunning on any device.
              </p>
              <p>
                Our mantra is “prototype early, and prototype often”—and we
                involve you in the design process from the beginning, allowing
                you to compare and evaluate different ideas before choosing the
                best path forward.
              </p>
            </div>
          </div>
          {/* End Hero Content */}
          {/* Hero Image */}
          <div className="col-xl-6 col-lg-6 col-md-6 col-xs-8 order-1 order-md-2">
            <div className="pt-lg-3" data-aos="flip-right" data-aos-delay={900}>
              <div className="l3-content-image-5 mt-5 mt-lg-0">
                <img className="w-100" src={img} alt="img" />
              </div>
            </div>
          </div>
          {/* End Hero Image */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
