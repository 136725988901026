import React from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Brands from "../sections/homepage/brands";
import CtaSec from "../sections/homepage/cta";
import Feature from "../sections/design/Feature";
import Img from "../sections/design/Hero";
import Tabs from "../sections/design/Tabs";
import Footer from "../components/Footer/Footer2";

const Design = () => {
  return (
    <>
      
      <PageWrapper themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              
              <Link to="/contact">
              <button className="btn btn-fox-violet header-btn-2 font-size-3 rounded-5">
                Let's talk
              </button>
              </Link>
            </>
          ),
          footerStyle: "style3",
        }}
        >
        
        <Img />
        <Tabs />
        <Feature />
        <Brands />
        <CtaSec />
        <Footer />
      </PageWrapper>
    </>
  );
};
export default Design;
