import React from "react";
import icon1 from "../../assets/image/media/design/png/DesignResponsiveLayout.png";
import icon2 from "../../assets/image/media/design/png/DesignUserFocused.png";
import icon3 from "../../assets/image/media/design/png/DesignVisualLogic.png";
import icon4 from "../../assets/image/media/design/png/DesignAccessible.png";
import icon5 from "../../assets/image/media/design/png/DesignAsset.png";
import icon6 from "../../assets/image/media/design/png/DesignDevelop.png";
import FeatureCard2 from "../../components/FreatureCard2/FreatureCard2";
const items = [
  {
    icon: icon1,
    title: "Responsive layouts",
    content:
      "Each of our designs is fully responsive, allowing it to adapt to different screen sizes or device types with no loss of quality or usability.",
  },
  {
    icon: icon2,
    title: "User-focused",
    content:
      "Every design decision we make is informed by the user experience, always seeking to make the final product as easy to use as possible.",
  },
  {
    icon: icon3,
    title: "Visual logic",
    content:
      "We use user psychology and design best practices to inform our choice of colors, typefaces, and layouts for maximum engagement.",
  },
  {
    icon: icon4,
    title: "Accessible design",
    content:
      "We’ll ensure that your product meets the required standards for accessibility in the regions or countries it will be available.",
  },
  {
    icon: icon5,
    title: "Asset optimization",
    content:
      "Design isn’t just about looking pretty — we’ll make sure all assets are correctly optimized for overall product performance.",
  },
  {
    icon: icon6,
    title: "Development oversight",
    content:
      "Our designers don’t just hand over the files and walk away—they’re involved in ensuring the build remains true to the agreed designs.",
  },
];

const Feature = () => {
  return (
    <div className="pt-24 pb-14 pb-md-18 pb-lg-24">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-8 col-md-8 col-xs-8">
            <div
              className="text-center mb-7 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <h2 className="font-size-10 letter-spacing-n83">
                And here’s what’s happening behind the scenes:
              </h2>
            </div>
          </div>
        </div>
        {/* End Section title */}
        <div className="row justify-content-center">
          {items.map((item, index) => (
            <div
              className="col-xl-6 col-lg-5 col-md-6 col-sm-9 col-xs-11 mb-7 mb-lg-15"
              data-aos="fade-up"
              data-aos-duration={900}
              key={index}
            >
              <FeatureCard2 options={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feature;
